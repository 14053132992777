import React, { useState } from "react";
import Layout from "../layouts";
import { graphql, Link } from "gatsby";
import SEO from "../components/seo";
import Background from "../components/background";
import BlogPostCard from "../components/blogs/blog-post-card";
import Toolbar from "@mui/material/Toolbar";
import { NewsletterForm } from "../components/newsletter-form";

const TemplateBlogCollection = (pageContext) => {

  const page_data = pageContext.data;
  const blog_references = page_data.allContentfulTemplateBlogPost.nodes;

  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useState(false);

  return (
    <Layout>
      <SEO
        description={page_data.contentfulTemplateStandard.description.description}
        title={page_data.contentfulTemplateStandard.title}
      />
      
      <div className="flex items-center flex-col relative z-0 mb-12">
        <div className="max-w-[850px] ml-auto mr-auto">
          <h1 className="font-montserrat text-orange text-center !text-[18px] uppercase">Blog</h1>
          <h3 className="font-montserrat text-dark-blue text-center font-bold !text-[18px] sm:!text-[24px] md:!text-[36px] leading-[105%]">Keep up with what’s going on around you.</h3>

          {/* form */}
          <div className="mt-12 flex justify-center items-center">
            <div className="max-w-[472px] w-full">
              <p className="font-montserrat font-medium leading-6 !text-[16px] text-center">Stay informed on our latest updates about marketing trends, best practices, and case studies.</p>
              <NewsletterForm/>
            </div>
          
          </div>
        </div>
        <Background/>
      </div>
      <div className="flex items-center justify-center">
        <div className="w-full max-w-[850px] ml-auto mr-auto">
          <Toolbar
            className="mb-12"
            component="nav"
            sx={{ alignItems: "center", justifyContent: "space-between", overflowX: "auto" }}
            variant="dense"
          >
            <Link activeClassName='active-link' className="sub_nav__margin text-dark-blue hover:text-orange no-underline capitalize font-montserrat font-bold text-[16px] leading-[159%]" onClick={() => setShow(false)} to="/category/all">All</Link>
            <Link activeClassName='active-link' className="sub_nav__margin text-dark-blue hover:text-orange no-underline capitalize font-montserrat font-bold text-[16px] leading-[159%]" onClick={() => setShow(false)} to="/category/ecommerce">Ecommerce</Link>
            <Link activeClassName='active-link' className="sub_nav__margin text-dark-blue hover:text-orange no-underline capitalize font-montserrat font-bold text-[16px] leading-[159%]" onClick={() => setShow(false)} to="/category/growth">Growth</Link>
            <Link activeClassName='active-link' className="sub_nav__margin text-dark-blue hover:text-orange no-underline capitalize font-montserrat font-bold text-[16px] leading-[159%]" onClick={() => setShow(false)} to="/category/digital">Digital</Link>
            <Link activeClassName='active-link' className="sub_nav__margin text-dark-blue hover:text-orange no-underline capitalize font-montserrat font-bold text-[16px] leading-[159%]" onClick={() => setShow(false)} to="/category/influencer">Influencer</Link>
            <Link activeClassName='active-link' className="sub_nav__margin text-dark-blue hover:text-orange no-underline capitalize font-montserrat font-bold text-[16px] leading-[159%]" onClick={() => setShow(false)} to="/category/creative">Creative</Link>
          </Toolbar>
        </div>
      </div>

      <div className="flex items-center justify-center flex-col mb-12">
        <div className="max-w-[1440px] ml-auto mr-auto w-full">
          <h2 className="margin-correction text-fix capitalize px-0 lg:px-8 font-montserrat text-dark-blue font-bold !text-[35px] md:!text-[40px] lg:!text-[50px] leading-[105%]">{pageContext.pageContext.section}</h2>
        </div>
        <div className="w-full">

          {blog_references.length > 1 && 
          <div className="max-w-[1440px] margin-correction ml-auto mr-auto grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-16 pt-8">
            {blog_references.map((blog, index) => {
              return (
                <BlogPostCard data={blog} key={index}/>
              );
            })}
          </div>}
          {blog_references.length === 0 &&
          <div className="max-w-[1440px] margin-correction ml-auto mr-auto flex justify-center items-center">
            <h4 className="font-montserrat text-dark-blue text-center !text-[18px] sm:!text-[24px] md:!text-[36px] leading-[105%]">Stay tuned for more...</h4>
          </div>}
        </div>
      </div>
    </Layout>
  );
};

export default TemplateBlogCollection;

export const query = graphql`
query TemplateBlogCollectionQuery($path: String, $section: String) {
  contentfulTemplateStandard(path: {eq: $path}) {
    title
    path
    description {
      description
    }
    sections {
      ... on ContentfulSectionBlogPostsSection {
        internal {
          type
        }
        heading
        featuredBlog {
          title
          path
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
            title
          }
          videoImagePlaceHolder {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
            title
          }
          description {
            description
          }
          author
          body {
            raw
          }
          date
          readTime
          category
        }
        additionalBlogs {
          title
          path
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
            title
          }
          videoImagePlaceHolder {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
            title
          }
          description {
            description
          }
          author
          body {
            raw
          }
          date
          category
          readTime
        }
      }
    }
  }
  allContentfulTemplateBlogPost(
    sort: {fields: date, order: DESC}
    filter: {category: {eq: $section}}
  ) {
    nodes {
      title
      category
      author
      image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
        title
      }
      description {
        description
      }
      date(formatString: "MMM DD, YYYY")
      path
      readTime
    }
  }
}
`;
